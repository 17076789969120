.dashboard__wrap--box {
	width: 130px;
	height: 130px;
	border-radius: 30px;
	background: #009fe3;
	-webkit-transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	/* margin: 1rem; */
}

.dashboard__wrap--box > a {
	/* padding: 2rem 1rem; */
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.dashboard__wrap--box:hover {
	background: #ec712e;
}

.dashboard__wrap--box img {
	max-width: 40px;
	max-height: 40px;
	/* width: 3rem;
	height: 3rem; */
	-o-object-fit: contain;
	object-fit: contain;
	/* margin-bottom: 2.5rem; */
}

.dashboard__wrap--box h2 {
	font-weight: bold;
	font-size: 14px;
}

.dashboard__wrap--box p {
	margin-top: 1.5rem;
	font-size: 1.2rem;
	letter-spacing: 0.01em;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	width: 100%;
}

.dashboard__wrap--box p span {
	display: block;
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 50%;
	background: #fff;
}

@media only screen and (max-width: 1024px) {
	/* .dashboard__wrap--box {
		width: 12rem;
		height: 12rem;
	} */
}

@media only screen and (max-width: 666px) {
	/* .dashboard__wrap--box {
		width: 10rem;
		height: 10rem;
	} */
	/* .dashboard__wrap--box img {
		width: 2rem;
		height: 2rem;
		-o-object-fit: contain;
		object-fit: contain;
	} */

	/* .dashboard__wrap--box h2 {
		font-weight: bold;
		font-size: 1rem;
		letter-spacing: 0.05em;
	} */
}
