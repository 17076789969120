@import url('https://fonts.googleapis.com/css?family=Red+Hat+Display:400,500,700,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
	font-family: 'Red Hat Display', sans-serif;
	margin: 0;
}

a {
	color: #484848;
	text-decoration: none;
	font-weight: 700;
}

a:hover {
	color: #000000;
	font-weight: 900;
}

.logo-login {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15%;
	width: 80%;
}

.main {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

/* .container {
	margin: 8px;
} */

.header {
	font-size: 30px;
	font-weight: 700;
}

.navbar {
	height: 35px;
	background-color: #f0f0f0;
	box-shadow: 2px 2px 0px #d0d0d0;
}

.header-buttons {
	margin: 0;
	float: right;
}
.navbar-logo {
	margin-left: 5px;
}

.float-right {
	float: right;
	margin-right: 10px;
}

.ruler {
	position: relative;
	width: 95%;
	margin: 20px auto;
	height: 14px;
}
.ruler .cm,
.ruler .mm {
	position: absolute;
	border-left: 1px solid #555;
	height: 14px;
	width: 8.33%;
}
.ruler .cm:after {
	position: absolute;
	bottom: -15px;
	font: 11px/1 sans-serif;
}
.ruler .mm {
	height: 5px;
}
.ruler .mm:nth-of-type(6) {
	height: 10px;
}
.ruler .cm:nth-of-type(1) {
	left: 0%;
}
.ruler .cm:nth-of-type(1):after {
	content: '0';
}
.ruler .cm:nth-of-type(2) {
	left: 8.33%;
}
.ruler .cm:nth-of-type(2):after {
	content: '2';
}
.ruler .cm:nth-of-type(3) {
	left: 16.66%;
}
.ruler .cm:nth-of-type(3):after {
	content: '4';
}
.ruler .cm:nth-of-type(4) {
	left: 24.99%;
}
.ruler .cm:nth-of-type(4):after {
	content: '6';
}
.ruler .cm:nth-of-type(5) {
	left: 33.32%;
}
.ruler .cm:nth-of-type(5):after {
	content: '8';
}
.ruler .cm:nth-of-type(6) {
	left: 41.65%;
}
.ruler .cm:nth-of-type(6):after {
	content: '10';
}
.ruler .cm:nth-of-type(7) {
	left: 49.98%;
}
.ruler .cm:nth-of-type(7):after {
	content: '12';
}
.ruler .cm:nth-of-type(8) {
	left: 58.31%;
}
.ruler .cm:nth-of-type(8):after {
	content: '14';
}
.ruler .cm:nth-of-type(9) {
	left: 66.64%;
}
.ruler .cm:nth-of-type(9):after {
	content: '16';
}
.ruler .cm:nth-of-type(10) {
	left: 74.97%;
}
.ruler .cm:nth-of-type(10):after {
	content: '18';
}
.ruler .cm:nth-of-type(11) {
	left: 83.3%;
}
.ruler .cm:nth-of-type(11):after {
	content: '20';
}
.ruler .cm:nth-of-type(12) {
	left: 91.63%;
}
.ruler .cm:nth-of-type(12):after {
	content: '22';
}
.ruler .cm:nth-of-type(13) {
	left: 100%;
}
.ruler .cm:nth-of-type(13):after {
	content: '24';
}
.ruler .mm:nth-of-type(1) {
	left: 0%;
}
.ruler .mm:nth-of-type(2) {
	left: 10%;
}
.ruler .mm:nth-of-type(3) {
	left: 20%;
}
.ruler .mm:nth-of-type(4) {
	left: 30%;
}
.ruler .mm:nth-of-type(5) {
	left: 40%;
}
.ruler .mm:nth-of-type(6) {
	left: 50%;
}
.ruler .mm:nth-of-type(7) {
	left: 60%;
}
.ruler .mm:nth-of-type(8) {
	left: 70%;
}
.ruler .mm:nth-of-type(9) {
	left: 80%;
}
.ruler .mm:nth-of-type(10) {
	left: 90%;
}
.ruler .mm:nth-of-type(11) {
	left: 100%;
}

.present {
	background: red;
}
