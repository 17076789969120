


.text_field {
  width: 100%;
  height: 4rem;
  padding: 2rem 0.8rem;
  border: none;
  border-bottom: 1px solid #707070;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.5);
}

.login hr {
  border: 0;
  height: 1px;
  background: rgba(112, 112, 112, 0.3);
  margin-bottom: 0.8rem;
}

.login__version {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.loginButton {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 20rem;
  height: 3.7rem;
  border-radius: 1.5rem;
  background: #fff;
  border: 1px solid #707070;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 2.2rem;
  font-weight: 500;
  color: #ec712e;

  margin-bottom: 1.4rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  justify-content: center;
  align-items: center;
}

.loginButton:hover {
  background: #ec712e;
  color: #fff;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.login__logo {
  width: 15rem;
  height: 9.4rem;
  margin-left: auto;
  margin-right: auto;
}

.login__logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.login_nav {
  height: 8rem;
  background: url("../../assets/nav-curve.png");
  background-position: right;
  background-size: 107% 100%;
  background-repeat: no-repeat;
  /* background-size: cover; */
  padding-left: 5rem;
}

.login_nav__logo {
  width: 15rem;
  height: 7.4em;
  /* margin-left: -100px; */
}

.login_nav__logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
